//Refactoring №2
/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useContext, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import jwtDecode from 'jwt-decode';
import { isEmpty } from 'lodash';
import EditorJS from '@editorjs/editorjs';
import Header from '@editorjs/header';
import Delimiter from '@editorjs/delimiter';
import Checklist from '@editorjs/checklist';
import Quote from '@editorjs/quote';
import Warning from '@editorjs/warning';
import Marker from '@editorjs/marker';
import List from '@editorjs/list';
import Code from '@editorjs/code';
import Table from '@editorjs/table';
import Embed from '@editorjs/embed';
import InlineCode from '@editorjs/inline-code';
import ImageTool from '@editorjs/image';
import uploader from '@ajite/editorjs-image-base64';

import {
  filesGetEffect,
  getPublicSharedFileEffect,
  filesGetNeyroEffect,
} from 'store/file/effects';
import { saveMemoEffect } from 'store/document/effects';
import MainContext from 'store/main/context/main-context';
import UserContext from 'store/home/contexts/user-context';
import { setCurrentPage } from 'store/main/actions';
import { getPreviewObject } from 'store/home/effects/file/get-preview-file-blob';
import fileFolderActionHandlerEffect from 'store/home/effects/entity-actions/entity-action-handler.effect';

import USER_ROLE_TYPES from 'config/user-role-types';
import getAuthToken from 'utils/auth/get-token';
import useNotification from 'utils/hooks/use-notification';

import ExitPopup, { ExitUntitledFilePopup } from './exit-popup';
import Spinner from 'components/Spinner';
import HintPopup from 'components/HintPopup';
import EditorName from './editor-name';
import Button, { ButtonTheme } from 'components/Button';
import ClickOutside from 'components/shared/ClickOutside';
import AccountSettingsButton from '../header/header-container/components/AccountSettingsButton';
import AccountSettingsMenu from '../header/header-container/components/AccountSettingsMenu';
import { ReactComponent as Share } from '../../static/assets/img/icons/action/editor-share.svg';

import styles from './style.module.scss';
import './style.css';
import classNames from 'classnames';

const EDITTOR_HOLDER_ID = 'editorjs';
const NEYRA_EXTENSION = 'neyra';

const EditorComponent = ({ match, history }) => {
  const {
    params: { fileId },
  } = match;
  const { t } = useTranslation('pages');
  const { dispatch: mainDispatch } = useContext(MainContext);
  const { user } = useContext(UserContext);
  const [previousContent, setPreviousContent] = useState('');
  const [content, setContent] = useState('');
  const [initialContent, setInitialContent] = useState('');
  const [file, setFile] = useState(null);
  const contentRef = useRef(null);
  contentRef.current = { content };
  const [isProfileMenuOpen, setIsProfileMenuOpen] = useState(false);
  const dispatch = useDispatch();
  const ejInstance = useRef();
  const userToken = getAuthToken();
  const isAuthorized = !!userToken;
  const isSharedDoc = isEmpty(user);
  const isGuest = user?.role === USER_ROLE_TYPES.GUEST;
  const [editor, setEditor] = useState(null);
  const [isSaveActive, setIsSaveActive] = useState(false);
  const [showExitPopup, setShowExitPopup] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [showUntitledModal, setShowUntitledModal] = useState(false);
  const [newName, setNewName] = useState('');
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const filename = searchParams.get('filename');
  const filesize = +searchParams.get('filesize');
  const isNeyraFile = file?.extension === NEYRA_EXTENSION;
  const fileNameWithExtension = newName + '.' + file?.extension;

  const { addNotification } = useNotification();

  useEffect(() => {
    return () => {
      ejInstance.current = null;
    };
  }, []);

  useEffect(() => {
    const allowSaving =
      JSON.stringify(previousContent.blocks) !==
        JSON.stringify(content.blocks) || file?.name !== fileNameWithExtension;

    allowSaving ? setIsSaveActive('active') : setIsSaveActive('');
  }, [content, newName]);

  const initEditor = (data, readOnly = false) => {
    setEditor(
      new EditorJS({
        holder: EDITTOR_HOLDER_ID,
        logLevel: 'ERROR',
        readOnly: isGuest || isSharedDoc || readOnly,
        data: initialContent || data,
        onReady: () => {
          ejInstance.current = editor;
        },
        onChange: async (data) => {
          handleEditorChange(data);
        },
        autofocus: true,
        tools: {
          header: { class: Header, shortcut: 'CMD+SHIFT+H' },
          list: { class: List, shortcut: 'CMD+SHIFT+L' },
          code: { class: Code, shortcut: 'CMD+SHIFT+D' },
          inlineCode: InlineCode,
          table: Table,
          embed: Embed,
          delimiter: Delimiter,
          checklist: Checklist,
          quote: Quote,
          warning: Warning,
          marker: Marker,
          image: {
            class: ImageTool,
            config: {
              uploader,
            },
          },
        },
      })
    );
  };

  const setNeyroContent = (text = '') => {
    const content = {
      time: new Date().getTime(),
      blocks: [
        {
          id: new Date().getTime(),
          type: 'paragraph',
          data: {
            text: text,
          },
        },
      ],
      version: '2.26.0',
      name: 'text',
    };
    setInitialContent(content);
    setPreviousContent(content);
    setContent(content);
    if (!ejInstance.current) {
      initEditor(content, true);
    }
  };

  const updateSearchParams = () => {
    const url = new URL(window.location);
    url.searchParams.set('filename', fileNameWithExtension);
    window.history.replaceState({}, '', url.toString());
  };

  useEffect(() => {
    const controller = new AbortController();
    const authFlow = () => {
      filesGetEffect(fileId, {
        filename: filename || file?.name,
        filesize: filesize || file?.size,
      }).then((data) => {
        const fileNameWithoutExtension = data.entry.name.slice(0, -6);
        setFile(data.entry);
        setNewName(fileNameWithoutExtension);
        if (data.entry.extension === NEYRA_EXTENSION) {
          filesGetNeyroEffect(fileId)
            .then(({ data }) => {
              setNeyroContent(data.text);
            })
            .catch((error) => {
              console.warn(error);
            });
        } else {
          getPreviewObject({ file: data.entry, controller }).then((data) => {
            if (data) {
              setInitialContent(data);
              setPreviousContent(data);
              setContent(data);
              if (!ejInstance.current) {
                initEditor(data);
              }
            }
          });
        }
      });
      mainDispatch(setCurrentPage({ name: 'Drive', url: '/main' }));
    };

    const noAuthFlow = () => {
      getPublicSharedFileEffect(fileId).then((file) => {
        setFile(file);
        getPreviewObject({ file, controller }).then((data) => {
          if (data) {
            data.name && setNewName(data.name);
            setInitialContent(data);
            setPreviousContent(data);
            setContent(data);
            if (!ejInstance.current) {
              initEditor(data);
            }
          }
        });
      });
    };

    if (isAuthorized && !isSharedDoc) {
      authFlow();
    } else {
      noAuthFlow();
    }
    return () => {
      controller.abort();
    };
  }, [fileId, mainDispatch, user?.public_address, isAuthorized]);

  const handleEditorChange = (content) => {
    setContent(content);
  };

  const onSaveContent = async (content) => {
    if (newName === '') {
      setShowUntitledModal(true);
      setIsSaving(false);
      return;
    }

    isAuthorized && !isSharedDoc;
    await saveMemoEffect({ content, memoSlug: fileId, name: file?.name });
    updateSearchParams();
    setContent(content);
    setIsSaving(false);
    setIsSaveActive(false);
    addNotification(t('changesSaved'), 'success');
  };
  const closeProfileMenu = () => {
    setIsProfileMenuOpen(false);
  };

  const openProfileMenu = () => {
    setIsProfileMenuOpen(!isProfileMenuOpen);
  };

  const optionClickHandler = (option) => (e) => {
    e.preventDefault();
    e.stopPropagation();
    dispatch(
      fileFolderActionHandlerEffect(file, option, { skipGetData: true })
    );
  };

  const signIn = () => {
    history.push('/');
  };
  const askBeforeExit = (event) => {
    event.preventDefault();
    if (isSaveActive === 'active') {
      setShowExitPopup(true);
      event.returnValue = true;
    }
  };

  const onCloseModal = () => setShowUntitledModal(false);

  const handleChange = (e) => {
    const name = e.target.value;
    setNewName(name);
  };

  useEffect(() => {
    if (isSaveActive === 'active') {
      window.onbeforeunload = askBeforeExit;
    } else {
      window.onbeforeunload = null;
    }
  }, [isSaveActive]);

  return (
    <section className={classNames('editor', styles.editorContainer)}>
      <header className="editor-header-fixed">
        <EditorName />
        <div id="editor-toolbar" />
        <div className="header-right-side">
          {!isNeyraFile && !isSharedDoc && (
            <button
              className={`${styles.saveBtn} ${styles[isSaveActive]}`}
              onClick={async () => {
                setIsSaving(true);
                let content = await editor.saver.save();
                onSaveContent(content);
                setPreviousContent(content);
              }}
            >
              {isSaving ? <Spinner /> : t('save')}
            </button>
          )}
          {!isAuthorized && (
            <Button
              className={styles.loginBtn}
              theme={ButtonTheme.DARK}
              onClick={signIn}
            >
              {t('login')}
            </Button>
          )}
          {!isGuest && isAuthorized && !isSharedDoc && (
            <Button
              className={styles.shareBtn}
              theme={ButtonTheme.DARK}
              onClick={optionClickHandler({
                name: 'Sharing',
                type: 'accessPreferences',
                permissions: ['edit'],
              })}
            >
              <Share />
            </Button>
          )}
          <ClickOutside onClickOutside={closeProfileMenu}>
            <div className="profile-button">
              {isAuthorized && (
                <>
                  <AccountSettingsButton
                    onClick={openProfileMenu}
                    isOpenedMenu={isProfileMenuOpen}
                    user={isAuthorized && jwtDecode(userToken)}
                  />
                  {isProfileMenuOpen && (
                    <AccountSettingsMenu
                      onClose={closeProfileMenu}
                      className="menu"
                    />
                  )}
                </>
              )}
            </div>
          </ClickOutside>
        </div>
      </header>
      <div className={classNames('editor-wrapper', styles.editorContainer)}>
        <div className={styles.inputWrapper}>
          <HintPopup
            horizontal="left"
            orientation="bottom"
            width="80px"
            hideArrow={true}
            content={<span>{t('rename')}</span>}
            poupClassName={styles.hintWrapper}
            disabled={isSharedDoc}
          >
            <input
              className={styles.nameInput}
              name="file_name"
              placeholder={t('untitled')}
              value={newName}
              onChange={handleChange}
              disabled={isSaving || isSharedDoc}
            />
          </HintPopup>
        </div>

        <div className="editor-container" id={EDITTOR_HOLDER_ID}></div>
      </div>
      {showExitPopup && (
        <ExitPopup
          isSaving={isSaving}
          save={async () => {
            setIsSaving(true);
            let content = await editor.saver.save();
            onSaveContent(content);
            setPreviousContent(content);

            window.onbeforeunload = null;

            setTimeout(() => {
              window.close();
            }, 1500);
          }}
          close={() => {
            setShowExitPopup(false);
          }}
          leavePage={() => {
            window.onbeforeunload = null;
            window.close();
          }}
        />
      )}
      {showUntitledModal && <ExitUntitledFilePopup close={onCloseModal} />}
    </section>
  );
};

export default EditorComponent;
